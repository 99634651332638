body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-link-Anton {
  font-family: "Anton", cursive;
  font-weight: 400;
}

.font-link-Cantarell {
  font-family: "Cantarell", sans-serif;
  font-weight: 400;
}

.font-link-OpenSans {
  font-family: "OpenSans", cursive;
  font-weight: 400;
}

.font-link-Benne {
  font-family: "Benne", cursive;
  font-weight: 400;
}

.font-link-Antonio {
  font-family: "Antonio", cursive;
  font-weight: 400;
}

.font-link-ArchivoNarrow {
  font-family: "Archivo Narrow";
  font-weight: 400;
}

.font-link-Righteous {
  font-family: "Righteous", cursive;
  font-weight: 400;
}

.font-link-Ibarra {
  font-family: "Ibarra Real Nova";
  font-weight: 600;
}

html {
  scroll-behavior: smooth;
}
